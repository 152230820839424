var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-top":"0"},attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{attrs:{"title":_vm.$t('roles.entityRoleList'),"color":"green","flat":"","full-width":""}},[[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.onShowModalAddEntityRole(null)}}},[_vm._v(" "+_vm._s(_vm.$t('common.add'))+" ")])],_c('v-data-table',{attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.entityRoleHeaders,"items":_vm.entityRoleList,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ 'test-primary': item.id == _vm.entityRoleSelected.id },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.selectEntityRoleRow(item)}}},[_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(item.stt))]),_c('td',{staticStyle:{"width":"35%"}},[_vm._v(_vm._s(item.name))]),_c('td',{staticStyle:{"width":"20%"}},[_vm._v(_vm._s(_vm.$t(_vm.getRoleInEntityName(item.roleType))))]),_c('td',{staticStyle:{"width":"20%"}},[_vm._v(_vm._s(_vm.$t(_vm.getEntityTypeName(item.entityType))))]),_c('td',{staticClass:"text-xs-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onShowModalAddEntityRole(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.update')))])]),(_vm.isSuperAdmin() || (_vm.isAdmin() && _vm.entityRoleHasEdit(item.id)))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"disabled":item.isBuiltin,"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.onShowModalDeleteEntityRole(item.id)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])]):_vm._e()],1)])]}}])})],2)],1)],1),(!_vm.isNull(_vm.entityRoleSelected.id))?_c('v-layout',[_c('v-flex',{attrs:{"md12":"","lg12":""}},[_c('entityRoleDetail',{attrs:{"entity-role-id":_vm.entityRoleSelected.id}})],1)],1):_vm._e(),_c('addEntityRoleModal',{ref:"addEntityRoleModal",on:{"onAdd":_vm.onAddNewEntityRole,"onUpdate":_vm.onUpdateEntityRole}}),_vm._l((_vm.GET_SNACK_BAR),function(snackbar){return _c('snack-bar',{key:snackbar.id,attrs:{"item":snackbar}})}),_c('confirm-modal',{ref:"confirmModal",attrs:{"title":_vm.confirmModalTitle},on:{"onConfirm":_vm.onConfirmDelete}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }