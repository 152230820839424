<template>
  <v-layout>
    <v-flex
      md12
      lg6>
      <material-card
        :title="$t('decentralizationGroup.availableGroups')"
        color="green"
        flat
        full-width
      >
        <v-list three-line>
          <v-list-tile
            v-for="(group, index) in availableGroups"
            :key="group + index"
            :class="index < availableGroups.length - 1 ? 'mb-3' : ''"
          >
            <v-list-tile-action v-if="isSuperAdmin() || (isAdmin() && entityRoleHasEdit())">
              <v-checkbox
                v-model="groupAvailableSelected"
                :value="group.id"
                color="green"
              />
            </v-list-tile-action>
            <v-list-tile-title>
              {{ group.name }}
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
        <v-tooltip
          v-if="groupAvailableSelected.length > 0"
          top>
          <template v-slot:activator="{ on }">
            <div style="text-align: right;">
              <v-btn
                small
                color="success"
                style="color: #ffffff;"
                v-on="on"
                @click="onAddGroupToRole()"
              >
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t("common.add") }}</span>
        </v-tooltip>
      </material-card>
    </v-flex>
    <v-flex
      md12
      lg6>
      <material-card
        :title="$t('decentralizationGroup.assignedGroups')"
        color="green"
        flat
        full-width
      >
        <v-list three-line>
          <v-list-tile
            v-for="(group, index) in assignedGroups"
            :key="group + index"
            :class="index < assignedGroups.length - 1 ? 'mb-3' : ''"
          >
            <v-list-tile-action v-if="isSuperAdmin() || (isAdmin() && entityRoleHasEdit())">
              <v-checkbox
                v-model="assignedGroupSelected"
                :value="group.id"
                color="green"
              />
            </v-list-tile-action>
            <v-list-tile-title>
              {{ group.name }}
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
        <v-tooltip
          v-if="assignedGroupSelected.length > 0"
          top>
          <template v-slot:activator="{ on }">
            <div style="text-align: right;">
              <v-btn
                small
                color="red"
                style="color: #ffffff;"
                v-on="on"
                @click="onRemoveGroupFromRole()"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t("common.delete") }}</span>
        </v-tooltip>
      </material-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import EntityRoleID from 'enum/entityRoleID'
export default {
  props: {
    entityRoleId: {
      type: Number,
      default: () => {
        return null
      }
    }
  },
  data: () => ({
    availableGroups: [],
    groupAvailableSelected: [],
    assignedGroups: [],
    assignedGroupSelected: []
  }),
  computed: {
    ...mapGetters([
      'GROUP_IN_OUT_SIDE_ENTITY_ROLE_LIST_DATA',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    GROUP_IN_OUT_SIDE_ENTITY_ROLE_LIST_DATA: function () {
      let data = this.GROUP_IN_OUT_SIDE_ENTITY_ROLE_LIST_DATA.data
      //   Set default selected
      this.groupAvailableSelected = []
      this.assignedGroupSelected = []
      // Set assigned groups
      let assignedGroups = data.assigned_groups
      this.assignedGroups = assignedGroups
      // Set avaialble group
      let availableGroups = data.available_groups
      this.availableGroups = availableGroups
    },
    entityRoleId: function () {
      let filter = {
        params: {
          roleId: this.entityRoleId
        }
      }
      this.GET_GROUP_IN_OUT_SIDE_ENTITY_ROLE(filter)
    }
  },
  created () {
    this.getGroupInOutSideEntityRole()
  },
  methods: {
    /**
     * Entity role has edit
     */
    entityRoleHasEdit: function () {
      if (
        this.entityRoleId === EntityRoleID.ADMIN_SUPPLIER ||
        this.entityRoleId === EntityRoleID.STAFF_SUPPLIER ||
        this.entityRoleId === EntityRoleID.ADMIN_AGENT ||
        this.entityRoleId === EntityRoleID.STAFF_AGENT
      ) {
        return false
      }
      return true
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Retrieve user info
     */
    retrieveUserInfo: function () {
      this.GET_USER_DATA()
    },
    /**
     * Get group in out side role
     */
    getGroupInOutSideEntityRole: function () {
      let filter = {
        params: {
          roleId: this.entityRoleId
        }
      }
      this.GET_GROUP_IN_OUT_SIDE_ENTITY_ROLE(filter)
    },
    /**
     * Add group to role
     */
    onAddGroupToRole: function () {
      let filter = {
        roleId: this.entityRoleId,
        groupIds: this.groupAvailableSelected
      }
      this.ADD_GROUP_TO_ENTITY_ROLE(filter).then(
        function () {
          this.getGroupInOutSideEntityRole()
          this.retrieveUserInfo()
        }.bind(this)
      )
    },
    /**
     * Remove group from role
     */
    onRemoveGroupFromRole: function () {
      let filter = {
        params: {
          groupIds: this.assignedGroupSelected
        }
      }
      this.REMOVE_GROUP_FROM_ENTITY_ROLE({
        id: this.entityRoleId,
        ...filter
      }).then(
        function () {
          this.getGroupInOutSideEntityRole()
          this.retrieveUserInfo()
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_GROUP_IN_OUT_SIDE_ENTITY_ROLE',
      'REMOVE_GROUP_FROM_ENTITY_ROLE',
      'ADD_GROUP_TO_ENTITY_ROLE',
      'GET_USER_DATA'
    ])
  }
}
</script>
