<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="isNull(entityRoleId) ? $t('roles.addNewRole') : $t('roles.updateRole')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs4
                md4>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="entityRoleName"
                  :label="$t('roles.roleName')"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs4
                md4>
                <v-select
                  v-model="entityRoleType"
                  :rules="[ruleRequiredValue]"
                  :items="entityRoleTypeList"
                  :label="$t('roles.roleType')"
                  :disabled="isBuiltin"
                  class="required"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                xs4
                md4>
                <v-select
                  v-model="entityType"
                  :items="entityTypeList"
                  :label="$t('roles.type.entity')"
                  :disabled="isBuiltin"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t('common.close') }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { FOR_CONASI_TARGET } from 'utils/constants'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import stringUtils from 'utils/stringUtils'
import EntityRoleType from 'enum/entityRoleType'
export default {
  data () {
    return {
      isShowModal: false,
      entityRoleName: null,
      entityRoleId: null,
      entityRoleType: EntityRoleType.STAFF,
      isBuiltin: false,
      entityRoleTypeList: stringUtils.EntityRoleList,
      entityType: null,
      entityTypeList: [
        {
          text: 'roles.type.all',
          value: null
        },
        ...stringUtils.EntityTypeName
      ],
      forConasi: FOR_CONASI_TARGET,
      valid: false
    }
  },
  methods: {
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Show modal
     */
    onShowModal: function (role) {
      this.entityRoleId = role && role.id
      this.entityRoleName = role && role.name
      this.entityRoleType = role && role.roleType
      this.entityType = role && role.entityType
      this.isBuiltin = role && role.isBuiltin
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.entityRoleName = null
      this.entityRoleType = null
      this.entityRoleId = null
      this.entityType = null
      this.isShowModal = false
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        if (!functionUtils.isNull(this.entityRoleId)) {
          this.$emit('onUpdate', this.entityRoleId, this.entityRoleName, this.entityRoleType, this.entityType)
        } else {
          this.$emit('onAdd', this.entityRoleName, this.entityRoleType, this.entityType)
        }
        this.isShowModal = false
        this.entityRoleName = null
        this.entityRoleId = null
        this.entityRoleType = null
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  .modal {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 21px !important;
  }
  /deep/.required label::after {
    content: " *";
    color: red;
  }
</style>
