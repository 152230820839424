const EntityRoleID = {
  // ADMIN_SUPPLIER: 1,
  // STAFF_SUPPLIER: 2,
  // ADMIN_AGENT: 3,
  // STAFF_AGENT: 4,
  // ACCOUNTANT: 5,
  // ADMIN_SITE: 6,
  // STAFF_SITE: 7
}

export default EntityRoleID
