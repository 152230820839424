<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('roles.entityRoleList')"
          color="green"
          flat
          full-width
        >
          <template>
            <v-btn
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddEntityRole(null)"
            >
              {{ $t('common.add') }}
            </v-btn>
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="entityRoleHeaders"
            :items="entityRoleList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr
                :class="{ 'test-primary': item.id == entityRoleSelected.id }"
                style="cursor: pointer;"
                @click="selectEntityRoleRow(item)">
                <td class="text-xs-center">{{ item.stt }}</td>
                <td style="width: 35%;">{{ item.name }}</td>
                <td style="width: 20%;">{{ $t(getRoleInEntityName(item.roleType)) }}</td>
                <td style="width: 20%;">{{ $t(getEntityTypeName(item.entityType)) }}</td>
                <td class="text-xs-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        color="primary"
                        v-on="on"
                        @click="onShowModalAddEntityRole(item)">
                        <v-icon dark>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('common.update') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="isSuperAdmin() || (isAdmin() && entityRoleHasEdit(item.id))"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="item.isBuiltin"
                        class="mx-2"
                        fab
                        small
                        color="red"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowModalDeleteEntityRole(item.id)">
                        <v-icon dark>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('common.delete') }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="!isNull(entityRoleSelected.id)">
      <v-flex
        md12
        lg12>
        <entityRoleDetail :entity-role-id="entityRoleSelected.id"/>
      </v-flex>
    </v-layout>
    <addEntityRoleModal
      ref="addEntityRoleModal"
      @onAdd="onAddNewEntityRole"
      @onUpdate="onUpdateEntityRole"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmDelete"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AddEntityRoleModal from './Modal/AddNewEntityRoleModal'
import ToastType from 'enum/toastType'
import EntityRoleDetail from './EntityRoleDetail'
import functionUtils from 'utils/functionUtils'
import EntityRoleID from 'enum/entityRoleID'
export default {
  components: {
    addEntityRoleModal: AddEntityRoleModal,
    entityRoleDetail: EntityRoleDetail
  },
  data: () => ({
    entityRoleHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'center'
      },
      {
        sortable: false,
        text: 'roles.roleName',
        value: 'name',
        align: 'left'
      },
      {
        sortable: false,
        text: 'roles.roleType',
        value: 'roleType',
        align: 'left'
      },
      {
        sortable: false,
        text: 'roles.type.entity',
        value: 'entityType',
        align: 'left'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    entityRoleList: [],
    entityRoleSelected: {
      id: null
    },
    confirmModalTitle: '',
    EntityRoleIdSelected: null
  }),
  computed: {
    ...mapGetters([
      'ENTITY_ROLE_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    ENTITY_ROLE_DATA () {
      let data = this.ENTITY_ROLE_DATA
      this.entityRoleList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let entityRoleObj = {
          stt: i + 1,
          id: data[i].id,
          name: data[i].name,
          roleType: data[i].type,
          entityType: data[i].entity_type,
          isBuiltin: data[i].is_default
        }
        this.entityRoleList.push(entityRoleObj)
      }
    }
  },
  created () {
    this.GET_ENTITY_ROLE_LIST()
  },
  methods: {
    /**
     * Entity role has edit
     */
    entityRoleHasEdit: function (id) {
      if (
        id === EntityRoleID.ADMIN_SUPPLIER ||
        id === EntityRoleID.STAFF_SUPPLIER ||
        id === EntityRoleID.ADMIN_AGENT ||
        id === EntityRoleID.STAFF_AGENT
      ) {
        return false
      }
      return true
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Retrieve user info
     */
    retrieveUserInfo: function () {
      this.GET_USER_DATA()
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    getRoleInEntityName: function (role) {
      return functionUtils.roleInEntityName(role)
    },
    getEntityTypeName: function (type) {
      return functionUtils.getEntityTypeName(type)
    },
    /**
     * Select row
     */
    selectEntityRoleRow: function (item) {
      this.entityRoleSelected.id = item.id
    },
    onShowModalDeleteEntityRole: function (id) {
      this.confirmModalTitle = this.$t('roles.onShowModalDeleteRoleTitle')
      this.EntityRoleIdSelected = id
      this.$refs.confirmModal.onShowModal()
    },
    onConfirmDelete: function () {
      this.onDeleteEntityRole()
    },
    /**
     * Delete group
     */
    onDeleteEntityRole: function () {
      this.DELETE_ENTITY_ROLE({ id: this.EntityRoleIdSelected }).then(
        function () {
          this.entityRoleSelected.id = null
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.retrieveUserInfo()
          this.GET_ENTITY_ROLE_LIST()
        }.bind(this)
      )
    },
    /**
     * Show modal add role
     */
    onShowModalAddEntityRole: function (role) {
      this.$refs.addEntityRoleModal.onShowModal(role)
    },
    /**
     * Add new role
     */
    onAddNewEntityRole: function (name, type, entityType) {
      let filter = {
        name: name,
        type: type,
        entityType: entityType
      }
      this.CREATE_ENTITY_ROLE(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.GET_ENTITY_ROLE_LIST()
        }.bind(this)
      )
    },
    /**
     * Update role
     */
    onUpdateEntityRole: function (entityRoleId, name, type, entityType) {
      let filter = {
        name: name,
        type: type,
        entityType: entityType
      }
      this.UPDATE_ENTITY_ROLE({ id: entityRoleId, ...filter }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.GET_ENTITY_ROLE_LIST()
        }.bind(this)
      )
    },
    ...mapActions([
      'CREATE_ENTITY_ROLE',
      'GET_ENTITY_ROLE_LIST',
      'DELETE_ENTITY_ROLE',
      'UPDATE_ENTITY_ROLE',
      'ON_SHOW_TOAST',
      'GET_USER_DATA'
    ])
  }
}
</script>
<style lang="scss" scoped>
/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
/deep/ .theme--light.v-list {
  background-color: transparent;
}
</style>
